exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-events-previous-tsx": () => import("./../../../src/pages/events/previous.tsx" /* webpackChunkName: "component---src-pages-events-previous-tsx" */),
  "component---src-pages-events-upcoming-tsx": () => import("./../../../src/pages/events/upcoming.tsx" /* webpackChunkName: "component---src-pages-events-upcoming-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-mdxeventlayout-tsx-content-file-path-home-runner-work-website-website-src-content-events-01-first-event-mdx": () => import("./../../../src/templates/mdxeventlayout.tsx?__contentFilePath=/home/runner/work/Website/Website/src/content/events/01-first-event.mdx" /* webpackChunkName: "component---src-templates-mdxeventlayout-tsx-content-file-path-home-runner-work-website-website-src-content-events-01-first-event-mdx" */),
  "component---src-templates-mdxeventlayout-tsx-content-file-path-home-runner-work-website-website-src-content-events-april-2023-event-mdx": () => import("./../../../src/templates/mdxeventlayout.tsx?__contentFilePath=/home/runner/work/Website/Website/src/content/events/april-2023-event.mdx" /* webpackChunkName: "component---src-templates-mdxeventlayout-tsx-content-file-path-home-runner-work-website-website-src-content-events-april-2023-event-mdx" */),
  "component---src-templates-mdxeventlayout-tsx-content-file-path-home-runner-work-website-website-src-content-events-may-2023-event-mdx": () => import("./../../../src/templates/mdxeventlayout.tsx?__contentFilePath=/home/runner/work/Website/Website/src/content/events/may-2023-event.mdx" /* webpackChunkName: "component---src-templates-mdxeventlayout-tsx-content-file-path-home-runner-work-website-website-src-content-events-may-2023-event-mdx" */),
  "component---src-templates-mdxlayout-tsx-content-file-path-home-runner-work-website-website-src-content-pages-contact-mdx": () => import("./../../../src/templates/mdxlayout.tsx?__contentFilePath=/home/runner/work/Website/Website/src/content/pages/contact.mdx" /* webpackChunkName: "component---src-templates-mdxlayout-tsx-content-file-path-home-runner-work-website-website-src-content-pages-contact-mdx" */)
}

